import React from "react"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutStyles from "../styles/pages/about.module.css"

import quote1_320px from "../images/Germaine-Reyes-320px.png"
import quote1_576px from "../images/Germaine-Reyes-576px.png"
import quote1_768px from "../images/Germaine-Reyes-768px.png"
import quote1_992px from "../images/Germaine-Reyes-992px.png"

import quote2_320px from "../images/Karen-Gayle-Sison-320px.png"
import quote2_576px from "../images/Karen-Gayle-Sison-576px.png"
import quote2_768px from "../images/Karen-Gayle-Sison-768px.png"
import quote2_992px from "../images/Karen-Gayle-Sison-992px.png"

import quote3_320px from "../images/Ma-Cristina-Llacer-Oreta-320px.png"
import quote3_576px from "../images/Ma-Cristina-Llacer-Oreta-576px.png"
import quote3_768px from "../images/Ma-Cristina-Llacer-Oreta-768px.png"
import quote3_992px from "../images/Ma-Cristina-Llacer-Oreta-992px.png"

const AboutPage = ({ data }) => (
  <Layout location="about">
    <SEO title="About" />
    <div className={ aboutStyles.whoWeAre }>
      <div className={ aboutStyles.whoWeAreContentWrapper }>
        <div className={ aboutStyles.whoWeAreContent }></div>
      </div>
      <div className={ aboutStyles.whoWeAreTextWrapper }>
        <div className={ aboutStyles.whoWeAreText }>
          <h1>Who We Are</h1>
          <p>Team ED is the SBU of of ED IMC Firm Corporation that provides management services to clients belonging to different business sectors. Team ED's curent roster of clients consists of various industries such as fast moving consumer goods (FMCGs), agribusiness, manufacturing, direct selling, digital enterprises and industry associations.</p>
          <p>Since 1998, Team ED have used our event industry expertise to produce extraordinary affairs. We are a smart mix of event planners, marketers, artists and logistics. We have been a trusted company to various groups of customers in producing unique and top-of-the-line events. We conceptualize, design, and execute brand experiences that produce great outcomes and allow our clients to accomplish the goals and objectives of the event.</p>
        </div>
      </div>
    </div>
    <div className={ aboutStyles.whatWeBelieve }>
      <div className={ aboutStyles.whatWeBelieveContentWrapper }>
        <div className={ aboutStyles.whatWeBelieveContent }></div>
      </div>
      <div className={ aboutStyles.whatWeBelieveTextWrapper }>
        <div className={ aboutStyles.whatWeBelieveText }>
          <h1>What We Believe</h1>
          <ul>
            <li>Exciting</li>
            <li>Memorable</li>
            <li>Professional</li>
          </ul>
          <p>These are the traits of what an event should be. And we at Team Executive Decisions are here to assist you in your event management needs.</p>
          <p>From corporate events, trade shows, company celebrations, and more, we believe an event should speak for you brand. Empowering it through our special events management, we ensure to deliver the message that you want to convey to your intended audience</p>
        </div>
      </div>
    </div>
    <div className={ aboutStyles.quotes }>
      <h1>What Others Think</h1>
      <hr/>
      <div className={ aboutStyles.quote }>
        <div className={ aboutStyles.quote1 }></div>
      </div>
      <div className={ aboutStyles.quote }>
        <div className={ aboutStyles.quote2 }></div>
      </div>
      <div className={ aboutStyles.quote }>
        <div className={ aboutStyles.quote3 }></div>
      </div>
    </div>
    <div className={ aboutStyles.affiliations }>
      <h1>Affiliations</h1>
      <hr/>
      <div className={ aboutStyles.logos }>
        <div className={ aboutStyles.logo }><Img fluid={ data.image1.childImageSharp.fluid } /></div>
        <div className={ aboutStyles.logo }><Img fluid={ data.image2.childImageSharp.fluid } /></div>
        <div className={ aboutStyles.logo }><Img fluid={ data.image3.childImageSharp.fluid } /></div>
        <div className={ aboutStyles.logo }><Img fluid={ data.image4.childImageSharp.fluid } /></div>
      </div>
      <div className={ aboutStyles.logos }>
        <div className={ aboutStyles.logo }><Img fluid={ data.image5.childImageSharp.fluid } /></div>
        <div className={ aboutStyles.logo }><Img fluid={ data.image6.childImageSharp.fluid } /></div>
        <div className={ aboutStyles.logo }><Img fluid={ data.image7.childImageSharp.fluid } /></div>
        <div className={ aboutStyles.logo }><Img fluid={ data.image8.childImageSharp.fluid } /></div>
        
      </div>
      <div className={ aboutStyles.logos }>
        <div className={ aboutStyles.logo }><Img fluid={ data.image9.childImageSharp.fluid } /></div>
        <div className={ aboutStyles.logo }><Img fluid={ data.image10.childImageSharp.fluid } /></div>
        <div className={ aboutStyles.logo } id={ aboutStyles.emptyLogo }></div>
        <div className={ aboutStyles.logo } id={ aboutStyles.emptyLogo }></div>
      </div>
    </div>
  </Layout>
)

export const quote = graphql`
  fragment quote on File {
    childImageSharp {
      fluid(maxWidth: 992) {
        aspectRatio
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "AD FOUNDATION LOGO.png" }) {
      ...quote
    }
    image2: file(relativePath: { eq: "ama.png" }) {
      ...quote
    }
    image3: file(relativePath: { eq: "AMIC.png" }) {
      ...quote
    }
    image4: file(relativePath: { eq: "ARAW VALUES AWARDS LOGO.png" }) {
      ...quote
    }
    image5: file(relativePath: { eq: "ASAP.png" }) {
      ...quote
    }
    image6: file(relativePath: { eq: "OAAP.png" }) {
      ...quote
    }
    image7: file(relativePath: { eq: "PISM.png" }) {
      ...quote
    }
    image8: file(relativePath: { eq: "PMA.png" }) {
      ...quote
    }
    image9: file(relativePath: { eq: "PMAP.png" }) {
      ...quote
    }
    image10: file(relativePath: { eq: "PSTD.png" }) {
      ...quote
    }
  }
`

export default AboutPage